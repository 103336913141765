// extracted by mini-css-extract-plugin
export var column = "InviteSignUpError__column__eAfvg";
export var fieldError = "InviteSignUpError__fieldError__T8pVA";
export var flex = "InviteSignUpError__flex__KpIIo";
export var flexColumn = "InviteSignUpError__flexColumn__e5Ibd";
export var gap1 = "InviteSignUpError__gap1__iSFmf";
export var gap2 = "InviteSignUpError__gap2__DssTn";
export var gap3 = "InviteSignUpError__gap3__pK9nk";
export var gap4 = "InviteSignUpError__gap4__IgtvD";
export var gap5 = "InviteSignUpError__gap5__BQ4_l";
export var row = "InviteSignUpError__row__Do1wS";