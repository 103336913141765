// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactTelInput from "../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as InviteSignUpData from "./InviteSignUpData.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as InviteSignUpEmailError from "./errors/InviteSignUpEmailError.res.js";
import * as InviteSignUpLastNameError from "./errors/InviteSignUpLastNameError.res.js";
import * as InviteSignUpPasswordError from "./errors/InviteSignUpPasswordError.res.js";
import * as InviteSignUpAgreementError from "./errors/InviteSignUpAgreementError.res.js";
import * as InviteSignUpFirstNameError from "./errors/InviteSignUpFirstNameError.res.js";
import * as InviteSignUpProviderScss from "./InviteSignUpProvider.scss";
import * as InviteSignUpCompanyNameError from "./errors/InviteSignUpCompanyNameError.res.js";
import * as InviteSignUpPhoneNumberError from "./errors/InviteSignUpPhoneNumberError.res.js";
import * as InviteSignUpPasswordConfirmError from "./errors/InviteSignUpPasswordConfirmError.res.js";

var css = InviteSignUpProviderScss;

function InviteSignUpProvider(props) {
  var match = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            if (action === "Validate") {
              return {
                      isSubmitted: state.isSubmitted,
                      input: state.input,
                      results: InviteSignUpData.Validate.all(state.input),
                      serverError: state.serverError
                    };
            } else {
              return {
                      isSubmitted: false,
                      input: state.input,
                      results: state.results,
                      serverError: state.serverError
                    };
            }
          }
          switch (action.TAG) {
            case "Update" :
                return {
                        isSubmitted: state.isSubmitted,
                        input: action._0,
                        results: state.results,
                        serverError: state.serverError
                      };
            case "SetServerError" :
                return {
                        isSubmitted: state.isSubmitted,
                        input: state.input,
                        results: state.results,
                        serverError: action._0
                      };
            case "SetIsSubmitted" :
                return {
                        isSubmitted: action._0,
                        input: state.input,
                        results: state.results,
                        serverError: state.serverError
                      };
            case "SetResults" :
                return {
                        isSubmitted: state.isSubmitted,
                        input: state.input,
                        results: action._0,
                        serverError: state.serverError
                      };
            
          }
        }), {
        isSubmitted: false,
        input: InviteSignUpData.Input.empty(props.email, props.token),
        results: undefined,
        serverError: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var submit = function () {
    var results = InviteSignUpData.Validate.all(state.input);
    dispatch({
          TAG: "SetResults",
          _0: results
        });
    if (InviteSignUpData.Validate.valid(Belt_Option.getExn(results))) {
      return $$Promise.wait(Api.inviteSignUp(state.input, "Provider"), (function (x) {
                    if (x.TAG === "Ok") {
                      return Url.visit(Routes_User.Personal.Dashboard.invitations);
                    }
                    SentryLogger.error1({
                          rootModule: "InviteSignUpProvider",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "InviteSignUpProvider.make"
                        }, "InviteSignUpProvider::Error", [
                          "Error",
                          x._0
                        ]);
                    var serverError = "Something went wrong";
                    dispatch({
                          TAG: "SetServerError",
                          _0: serverError
                        });
                    dispatch("AfterSubmit");
                  }));
    } else {
      return dispatch("AfterSubmit");
    }
  };
  var beforeSubmit = function () {
    dispatch({
          TAG: "SetIsSubmitted",
          _0: true
        });
    submit();
  };
  var error = state.serverError;
  var res = state.results;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Please complete your registration process first, To accept your invitation.",
                      className: css.signUpInfo
                    }),
                JsxRuntime.jsxs(Form.make, {
                      className: css.signUpForm,
                      onSubmit: (function (param) {
                          beforeSubmit();
                        }),
                      children: [
                        error !== undefined ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(P.make, {
                                            children: error
                                          }),
                                      className: css.errors
                                    }),
                                className: css.errorContainer
                              }) : null,
                        res !== undefined && !InviteSignUpData.Validate.valid(res) ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(P.make, {
                                            children: "Please, fix all errors."
                                          }),
                                      className: css.errors
                                    }),
                                className: css.errorContainer
                              }) : null,
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUp-form--first-name",
                                              children: "First name"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUp-form--first-name",
                                              value: state.input.firstName,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.firstName.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: $$event.target.value,
                                                          lastName: init.lastName,
                                                          email: init.email,
                                                          token: init.token,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: init.password,
                                                          passwordConfirm: init.passwordConfirm,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(InviteSignUpFirstNameError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUp-form--last-name",
                                              children: "Last name"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUp-form--last-name",
                                              value: state.input.lastName,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.lastName.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: init.firstName,
                                                          lastName: $$event.target.value,
                                                          email: init.email,
                                                          token: init.token,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: init.password,
                                                          passwordConfirm: init.passwordConfirm,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(InviteSignUpLastNameError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    })
                              ],
                              className: Cx.cx([
                                    css.fieldRow,
                                    css.multiple
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "signUp-form--email",
                                      children: "Email"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "signUp-form--email",
                                      value: state.input.email,
                                      disabled: true,
                                      status: Belt_Option.map(state.results, (function (x) {
                                              if (x.email.TAG === "Ok") {
                                                return "Valid";
                                              } else {
                                                return "Error";
                                              }
                                            }))
                                    }),
                                JsxRuntime.jsx(InviteSignUpEmailError.make, {
                                      results: state.results
                                    })
                              ],
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "signUp-form--phone",
                                            children: "Phone"
                                          }),
                                      JsxRuntime.jsx(TelephoneField.make, {
                                            id: "signUp-form--phone",
                                            value: state.input.phoneNumber,
                                            defaultCountry: "us",
                                            status: Belt_Option.map(state.results, (function (x) {
                                                    if (x.phoneNumber.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value, selectedCountry) {
                                                var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        email: init.email,
                                                        token: init.token,
                                                        companyName: init.companyName,
                                                        phoneNumber: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                        dialCountryCode: "+" + country.dialCode,
                                                        country: country.iso2.toUpperCase(),
                                                        password: init.password,
                                                        passwordConfirm: init.passwordConfirm,
                                                        isAgreeWithTerms: init.isAgreeWithTerms,
                                                        isReceiveNews: init.isReceiveNews
                                                      }
                                                    });
                                              }),
                                            preferredCountries: ["us"]
                                          }),
                                      JsxRuntime.jsx(InviteSignUpPhoneNumberError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.fieldColumn,
                                          css.half
                                        ])
                                  }),
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "signUp-form--company-name",
                                      children: "Company name"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "signUp-form--company-name",
                                      value: state.input.companyName,
                                      status: Belt_Option.map(state.results, (function (x) {
                                              if (x.companyName.TAG === "Ok") {
                                                return "Valid";
                                              } else {
                                                return "Error";
                                              }
                                            })),
                                      onChange: (function ($$event) {
                                          var init = state.input;
                                          dispatch({
                                                TAG: "Update",
                                                _0: {
                                                  firstName: init.firstName,
                                                  lastName: init.lastName,
                                                  email: init.email,
                                                  token: init.token,
                                                  companyName: $$event.target.value,
                                                  phoneNumber: init.phoneNumber,
                                                  dialCountryCode: init.dialCountryCode,
                                                  country: init.country,
                                                  password: init.password,
                                                  passwordConfirm: init.passwordConfirm,
                                                  isAgreeWithTerms: init.isAgreeWithTerms,
                                                  isReceiveNews: init.isReceiveNews
                                                }
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx(InviteSignUpCompanyNameError.make, {
                                      results: state.results
                                    })
                              ],
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUp-form--password",
                                              children: "Password"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUp-form--password",
                                              type_: "Password",
                                              value: state.input.password,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.password.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: init.firstName,
                                                          lastName: init.lastName,
                                                          email: init.email,
                                                          token: init.token,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: $$event.target.value,
                                                          passwordConfirm: init.passwordConfirm,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(InviteSignUpPasswordError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUp-form--password-confirm",
                                              children: "Confirm password"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUp-form--password-confirm",
                                              type_: "Password",
                                              value: state.input.passwordConfirm,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.passwordConfirm.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: init.firstName,
                                                          lastName: init.lastName,
                                                          email: init.email,
                                                          token: init.token,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: init.password,
                                                          passwordConfirm: $$event.target.value,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(InviteSignUpPasswordConfirmError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    })
                              ],
                              className: Cx.cx([
                                    css.fieldRow,
                                    css.multiple
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(Checkbox.WithLabel.make, {
                                            id: "sign-up--agree",
                                            size: "MD",
                                            checked: state.input.isAgreeWithTerms,
                                            disabled: false,
                                            visuallyDisabled: false,
                                            onChange: (function (param) {
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        email: init.email,
                                                        token: init.token,
                                                        companyName: init.companyName,
                                                        phoneNumber: init.phoneNumber,
                                                        dialCountryCode: init.dialCountryCode,
                                                        country: init.country,
                                                        password: init.password,
                                                        passwordConfirm: init.passwordConfirm,
                                                        isAgreeWithTerms: !state.input.isAgreeWithTerms,
                                                        isReceiveNews: init.isReceiveNews
                                                      }
                                                    });
                                              }),
                                            children: [
                                              "I agree to the ",
                                              JsxRuntime.jsx("a", {
                                                    children: "Terms & Conditions",
                                                    href: "/terms-of-use"
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(InviteSignUpAgreementError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: css.checkboxLabel
                                  }),
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                          id: "sign-up--news",
                                          size: "MD",
                                          checked: state.input.isReceiveNews,
                                          disabled: false,
                                          visuallyDisabled: false,
                                          onChange: (function (param) {
                                              var init = state.input;
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      firstName: init.firstName,
                                                      lastName: init.lastName,
                                                      email: init.email,
                                                      token: init.token,
                                                      companyName: init.companyName,
                                                      phoneNumber: init.phoneNumber,
                                                      dialCountryCode: init.dialCountryCode,
                                                      country: init.country,
                                                      password: init.password,
                                                      passwordConfirm: init.passwordConfirm,
                                                      isAgreeWithTerms: init.isAgreeWithTerms,
                                                      isReceiveNews: !state.input.isReceiveNews
                                                    }
                                                  });
                                            }),
                                          children: "Receive latest industry news"
                                        }),
                                    className: css.checkboxLabel
                                  }),
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Button.make, {
                                    size: "MD",
                                    color: "Primary",
                                    busy: state.isSubmitted,
                                    submit: true,
                                    className: css.submitButton,
                                    children: "Update"
                                  }),
                              className: css.fieldRow
                            })
                      ]
                    })
              ],
              className: css.signUpIndividual
            });
}

var Input;

var Validate;

var ValidationResult;

var ReactTelInputData;

var make = InviteSignUpProvider;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
